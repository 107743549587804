export function swiper() {
    var swiper = new Swiper(".mySwiper", {
        slidesPerView: "2.5",
        spaceBetween: 0,
        loop: true,
        speed: 800,
        
        autoplay: {
          delay: 1000, // スライド間の待機時間 (ミリ秒)
          disableOnInteraction: false, // ユーザーが操作しても自動再生を止めない
        },
        
        breakpoints: {
          768: {
            slidesPerView: 4.5, // 2枚表示
          },

        }
      });
}
