// import { accordion } from "./_accordion";
import { swiper } from "./_swiper";

// ページの内容が完全に読み込まれた後のイベント
document.addEventListener('DOMContentLoaded', () => {
    // メインタグのID取得
    const mainElement = document.querySelector('main');
    const pageId = mainElement ? mainElement.getAttribute('id') : null;

    // ページ固有の関数実行
    const pageFunctions = {
        // 'top': [accordion], //TOPページにて実行
    };

    // drawerは常に実行
    // drawer();
    swiper();

    // ページ固有の関数を実行
    if (pageId && pageFunctions[pageId]) {
        pageFunctions[pageId].forEach(fn => {
            if (typeof fn === 'function') {
                try {
                    fn();
                } catch (error) {
                    console.error(`ページID ${pageId} の関数実行中にエラーが発生しました:`, error);
                }
            } else {
                console.error(`ページID ${pageId} に対して関数が期待されましたが、${typeof fn} が得られました`);
            }
        });
    }
});

document.addEventListener("DOMContentLoaded", function() {
    const userAgent = window.navigator.userAgent;
  
    if (userAgent.indexOf("Firefox") > -1) {
      document.body.classList.add("firefox");
    } else if (userAgent.indexOf("Edg") > -1) {
      document.body.classList.add("edge");
    } else if (userAgent.indexOf("Safari") > -1 && userAgent.indexOf("Chrome") === -1) {
      document.body.classList.add("safari");
    }
  });